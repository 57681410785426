<template>
  <section class="m-better-product m-scroll">
    <!-- 头部导航 -->
    <div class="header">
      <img @click="handleBack" src="@/assets/order/icon-fanhui@2x.png" alt="back">
      <span>{{$route.query.title}}</span>
      <router-link to="/zh/cart" >
        <img   src="@/assets/order/icon_gouwu@2x.png" alt="img">
        <m-dot :number="cartNum" :animate="dotAnimate" ref="dot"></m-dot>
      </router-link>
    </div>

    <!-- 滚动切换 -->
 
    <van-list  v-model="loading" :finished="finished" finished-text="......" loading-text="loading..." :immediate-check="false" @load="onLoad">

      <goods-columns @cartClick="handleCart" :data="goodsList" :loading="goodsLoad" :columns="1"></goods-columns>

    </van-list>

    




    <!-- <van-loading v-show="loading" type="spinner" color="#ED2A24" size="6.4vw"></van-loading> -->

    <!-- 选择SKU 加入购物车 -->
    <m-sku title="加入购物车" :show="skuModal" @close="val=>skuModal=val" @success="handleCartSuccess" :goodsId="goodsId"></m-sku>

    <!-- 加入购物车动画 -->
    <m-animate :start="start" :dom="start_dom" @end="handleCartEnd"></m-animate>

    <!-- 购物车浮窗 -->
    <transition name="van-fade">
        <CartFlex v-if="start" />
    </transition>





  </section>
</template>

<script>
import MDot         from '@/components/zh/m-dot.vue'
import MSku         from '@/components/zh/m-sku.vue'
import GoodsColumns from '@/components/zh/goods-columns.vue'
import MAnimate     from '@/components/zh/cart-animate.vue'
import CartFlex     from '@/components/zh/cart-flex.vue'

import { classify_goods } from '@/api/zh/merchant.js'
export default {
  name:'Index',
  components:{   MDot, GoodsColumns, MSku, MAnimate, CartFlex },
  data(){
    return {
      cartNum: 10,
      dotAnimate: false,


      goodsList: [],
      page: 1,
      loading:false,
      finished:false,
      goodsLoad: false,

      goodsId: '',
      skuModal:false,
      start:false,
      start_dom:null,
    }
  },



  methods:{
   
    handleBack(){
      this.$router.go(-1)
    },

    

    
    
   
    // 商品列表购物车点击事件
    handleCart(goods,e){
      this.goodsId = goods
      this.skuModal  = true
      this.start_dom = e 
    },
     // sku 弹窗加入购物车成功回调
    handleCartSuccess(){
      this.skuModal = false
      let timer = setTimeout( () => {
        this.start  = true
        this.dotAnimate = true 
        this.cartNum ++

        let timer1 = setTimeout(()=> {
          this.dotAnimate = false
          clearTimeout(timer1)
        },400)

        clearTimeout(timer)
      },300)

      
    },
    // 加入购物车动画结束
    handleCartEnd(){
      this.start    = false
    },

 
    getGoods() {
     let params = {
       shops_id: this.$route.query.id,
       type_id: this.$route.query.tid,
       page: this.page
     }
     this.goodsLoad = true
      classify_goods(params).then(res => {
        if(res) {
          this.goodsList = this.goodsList.concat(res.data.data)
          if(this.page >= res.data.last_page) {
            this.finished = true
          }
        }
      }).finally(() => {
        this.loading = false
        this.goodsLoad = false
      } )
    },

    // 上拉加载
    onLoad(){
      this.page++
      this.getGoods()
    },


  },
  
  created() {
    this.getGoods()
  }
}
</script>

<style lang="less" scoped>
@import './goods-list.less';
</style>